/* global remove user-agent styling */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* custom vars */
  :root {
    --color-background: #F0F2F5;
    --color-text: #FCFDFF;
  
    --color-primary: #000;
    --color-secondary: #F1972C;
    --color-secondary-hover: #FA9C2D;
    
    --color-input-background: #F8F8FC;
    --color-box-footer: #FAFAFC;
    --color-box-base: #FFFFFF;
    --color-button-text: #FFFFFF;
    --color-hairline-in-dark: #626264; /*#4F4F5B;*/
    --color-hairline-in-light: #E1E3E5;
  
    --color-card-background: var(--color-text);
    --color-card-title: #5A5A66;
    --color-card-label: #BFBFCC;
    --color-card-content: #787880;
  
    --color-cancel: #E1E3E5;
    --color-delete: #EB3B35; 
    --color-save: #36B336;
    
    --color-badge-progress-text: var(--color-save);
    --color-badge-progress-background: #E8F8E8;
    --color-badge-done-text: var(--color-delete);
    --color-badge-done-background:#FAECEB;
  
    --color-header-inner-title: var(--color-card-label)
  
  }
  
  /* user-agent font-size is 16px, let's make it responsive */
  @media(max-width: 1080px) {
    html {
      font-size: 93.75%; /* 15px */
    }
  }
  
  @media(max-width: 970px) {
    html {
      font-size: 87.5%; /* 14px */
    }
  }
  
  @media(max-width:700px) {
    :root {
      font-size: 75%; /* 12px */
    }
  }
  
  @media(max-width: 590px) {
    :root {
      font-size: 62.25%; /* 10px */
    }
  }
  
  /* tags styling */
  body {
    background: var(--color-background);
  }
  
  body,
  input,
  button,
  textarea {
    font: 500 1rem Inter;
    color: var(--color-text);
    border: none;
  }
  
  a {
    text-decoration: none;
  }
  
  /* basic classes */
  .container {
    width: min(1440px, 90vw);
    margin: 0 auto;
  }
  
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  
  .separator {
    height: 1px;
    background-color: var(--color-hairline-in-dark);
    margin: 1rem 0;
  }
  
  .separator.light {
    background-color: #E1E3E5;
  }
  
  .flex {
    display: flex;
  }


/***index***/

  #top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  #logo {
    width: 13rem;
    height: 3rem;
  }
  
  /* 
   * notification
  /* -------------------------------------------------- */
  #notification {
    display: flex;
    align-items: center;
  
    font-family: Inter;
    font-weight: 500;
    font-size: 1rem;
  }
  
  #notification img {
    margin-right: 0.5rem; /* 8px of 16px root*/
  }
  
  /* 
   * avatar
  /* -------------------------------------------------- */
  #avatar-profile {
    display: flex;
    align-items: center;
    color: var(--color-white);
    text-decoration: none;
  
    font-family: IBM Plex Sans;
    text-align: right;
  }
  
  #avatar-profile p {
    font-size: 1.25rem; /* 20px of 16px root*/
    line-height: 1.875rem; /*30px of 16px root*/
    margin-right: 1.25rem;
  
    font-weight: 600;
  }
  
  #avatar-profile span {
    display: block;
  
    font-weight: normal;
    font-size: 0.875rem; /* 14px of 16px root*/
    line-height: 1.5rem; /* 24px of 16px root*/
  
    font-weight: 400;
  }
  
  #avatar-profile p span:hover {
    text-decoration: underline;
    color:var(--color-secondary);
  }
  
  #avatar-profile img {
    width: 4rem; /* 64px of 16px root*/
    height: 4rem; /* 64px of 16px root*/
    border-radius: 50%;
    border: 0.156rem solid #F1972C; /* 2.5px of 16px root*/
  }
  
  /* 
   * summary
  /* -------------------------------------------------- */
  #summary {
    margin-top: 2rem;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* 
   * summary info
  /* -------------------------------------------------- */
  #summary .info {
    display:flex;
    font-family: Inter;  
    margin-bottom: 2rem;
  }
  
  .info > div {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.625rem;
    
    margin-right: 2.5rem; /* 40px of 16px root*/
  }
  
  .info > div > strong {
    display: block;
    
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.625rem; /* 26px of 16px root*/
    
  }
  
  /* 
   * summary button
  /* -------------------------------------------------- */
  #summary .button {
    padding: 0.75rem 1.25rem 0.75rem 0.75rem;
    margin-bottom: 2.5rem;
  }
  
  #summary .button span::before {
    width: 2rem;
    height: 2rem;
    content: " "; 
    background-color: var(--color-background);
    opacity: 0.16;
    border-radius: 0.313rem; /* 5px of 16px root*/
  
    position: absolute;
  }
  
  #summary .button span {
    width: 2rem;
    height: 2rem;
  
    margin-right: 2rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #summary .button span img {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  /* 
   * main
  /* -------------------------------------------------- */
  main {
    margin-top: -2.825rem;
  }
  
  
  /* 
   * CARD
  /* -------------------------------------------------- */
  .card {
    display: grid;
    grid-template-columns: 100%;
    align-items: center;
  
    margin-bottom:  0.5rem;
  
    position: relative;
  
    transition: all 0.2s;
  }
  
  .card:hover {
    background: white;
  }
  
  .card::before {
    content: ' ';
    
    width: .25rem;
    height: 0%;
    background-color: var(--color-secondary);
  
    border-radius: .313rem 0 0 .313rem;
  
    position: absolute;
    top: 0;
    left: -1px;
  
    transition: all .2s;
  }
  
  .card:hover::before {
    height:100%;
  }
  
  .card .column:last-child {
    justify-self: flex-end;
  }
  
  .card .column > span {
    display:block;
  
    font-family: Inter;
    font-weight: 600;
    font-size: 0.75rem; /* 12px of 16px root*/
    text-transform: uppercase;
  
    color: var(--color-card-label);
  }
  
  .progress .column p {
    font-weight: 600;
  }
  
  .column.id {
    font-family: IBM Plex Sans;
    font-weight: 600;
    color: var(--color-card-label);
  }
  
  .column.name {
    font-family: IBM Plex Sans;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.125rem;
  }
  
  .column.actions button img {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  @media(max-width:1390px) {
    .card {
      grid-template-columns: 100%;
    }
  }
  
  @media(max-width:970px) {
    .card {
      grid-template-columns: 100%;
    }
    
    .column.id {
      display: none;
    }
  }
  
  @media(max-width:770px) {
    .card {
      grid-template-columns: 1fr ;
      /*grid-template-areas: 
      "lt rt"
      "lm rm"
      "lb rb"
      ;*/
      gap: .5rem;
      margin-top: 35px;
    }

    .titulopdf{
      font-size:30px !important;
    }
  
    .column.id {
      display: none;
    }
  
    .column.name {
      grid-area: lt;
    }
    .column.deadline {
      grid-area: lm;
    }
    .column.amount {
      grid-area: lb;
    }
    .column.status {
      justify-self: end;
      grid-area: rt;
    }
    .column.actions{
      grid-area: rb;
    }
  }


  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 45vh;
    padding: 0 4rem 2rem;
  }
  
  .box:not(:first-child) {
    height: 45rem;
  }
  
  .box__title {
    font-size: 10rem;
    font-weight: normal;
    letter-spacing: 0.8rem;
    margin-bottom: 2.6rem;
  }
  
  .box__title::first-letter {
    color: #000;
  }
  
  .box__p,
  .box__info,
  .box__note {
    font-size: 1.6rem;
  }
  
  .box__info {
    margin-top: 6rem;
  }
  
  .box__note {
    line-height: 2;
  }
  
  /* modal */
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  
    display: none;
    justify-content: center;
    align-items: center;
  
    width: 100%;
    height: 100%;
  
    /* --m-background is set as inline style */
    background: var(--color-background);
  }
  
  /* using :target */
  /*
  when users will click/enter button(link) browser will add a #id in a url and when that happens we'll show our users the modal that contains that id.
  */
  .modal-container:target {
    display: flex;
  }

  .teste{
    display: flex;
  }

  .testefehca{
    display:none;
  }
  
  .modal {
    width: 35rem;
    padding: 4rem 2rem;
    border-radius: 0.8rem;
  
    color: #000;
    background: var(--color-background);
    box-shadow: var(--m-shadow, 0.4rem 0.4rem 10.2rem 0.2rem) var(--shadow-1);
    position: relative;
  
    overflow: hidden;
  }
  
  .modal__title {
    font-size: 3.2rem;
    color:#000;
  }
  
  .modal__text {
    padding: 0 4rem;
    margin-top: 4rem;
  
    font-size: 1.6rem;
    line-height: 2;
  }
  
  .modal__btn {
    margin-top: 4rem;
    padding: 1rem 1.6rem;
    border: 1px solid var(--border-color);
    border-radius: 100rem;
  
    color: inherit;
    background: transparent;
    font-size: 1.4rem;
    font-family: inherit;
    letter-spacing: 0.2rem;
  
    transition: 0.2s;
    cursor: pointer;
  }
  
  .modal__btn:nth-of-type(1) {
    margin-right: 1rem;
  }
  
  .modal__btn:hover,
  .modal__btn:focus {
    background: var(--focus);
    border-color: var(--focus);
    transform: translateY(-0.2rem);
  }
  
  /* link-... */
  .link-1 {
    font-size: 1.8rem;
  
    color: #000;
    background: var(--background);
    box-shadow: 0.4rem 0.4rem 2.4rem 0.2rem var(--shadow-1);
    border-radius: 100rem;
    padding: 1.4rem 3.2rem;
  
    transition: 0.2s;
  }
  
  .link-1:hover,
  .link-1:focus {
    transform: translateY(-0.2rem);
    box-shadow: 0 0 4.4rem 0.2rem var(--shadow-2);
  }
  
  .link-1:focus {
    box-shadow: 0 0 4.4rem 0.2rem var(--shadow-2),
      0 0 0 0.4rem var(--global-background), 0 0 0 0.5rem var(--focus);
  }
  
  .link-2 {
    width: 4rem;
    height: 4rem;
    border: 1px solid var(--border-color);
    border-radius: 100rem;
  
    color: inherit;
    font-size: 2.2rem;
  
    position: absolute;
    top: 2rem;
    right: 2rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
  
    transition: 0.2s;
  }
  
  .link-2::before {
    content: "×";
  
    transform: translateY(-0.1rem);
  }
  
  .link-2:hover,
  .link-2:focus {
    background: var(--focus);
    border-color: var(--focus);
    transform: translateY(-0.2rem);
  }
  
  .abs-site-link {
    position: fixed;
    bottom: 20px;
    left: 20px;
    color: hsla(0, 0%, 1000%, 0.6);
    font-size: 1.6rem;
  }

